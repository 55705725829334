import React from "react";
import "./GetStarted.css";
// Ensure the image path is correct and the image exists in the specified directory
import getStarted from "../../assets/cute-cat.png"; 
import { Container } from "react-bootstrap";

export default function GetStarted() {
  return (
    <section id="getStarted">
      <img
        src={getStarted}
        alt="Cute kitten"
        className="startedImg"
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-delay="10"
        data-aos-duration="370"
        easing="ease-in-cubic"
        style={{ width: '30%', height: 'auto' }} // Adjust the width as needed
      />
      <Container className="container">
        <div className="allText">
          <h1
            className="title"
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="10"
            data-aos-duration="370"
          >
            Protect Your Pets. 
          </h1>
          <p
            className="info"
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="10"
            data-aos-duration="370"
          >
We understand that your furry companions are more than just pets—they’re family. Whether they’re wagging their tails, purring, their well-being matters to you, and it matters to us too.
          </p>
          <a
            href="mailto:rockypethealthinsurance@gmail.com"
            className="getStartedBtn"
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="20"
            data-aos-duration="1000"
            easing="ease-in"
            style={{ textDecoration: 'none' }}
          >
            <strong style={{ color: '#F0FDFA'}}>Know More</strong> 
          </a>
        </div>
      </Container>
    </section>
  );
}
