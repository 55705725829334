import React from "react";
import "./Importance.css";
import art from "../../assets/pet background.jpg";
import { Container } from "react-bootstrap";

export default function Importance() {
  return (
    <section id="importance" className="importance-section">
      <Container className="container">
        {/* <img src={art} alt="not found" /> */}
        <h1 className="title">
          Why We Are
          <br /> Different
        </h1>
        <div
          className="first-left"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="370"
          data-aos-delay="10"
        >
          <h2 className="first-left-title">What makes
Rocky accessible</h2>
          <p className="first-left-info">
          We believe that insurance should provide you with peace of mind, knowing that you're covered in case of an unexpected event.
          </p>
        </div>
        <div
          className="first-right"
          data-aos="fade-left"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="10"
          data-aos-duration="370"
        >
          <h2 className="first-right-title">Healthcare in your fingertips</h2>
          
          <div className="first-right-info">
          That's why we offer personalised support and guidance throughout the insurance process
          </div>
        </div>
        <div
          className="second-left"
          data-aos="fade-right"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="370"
          data-aos-delay="10"
        >
          <h2 className="second-left-title">Peace of Mind, One Paw at a Time</h2>
          <p className="second-left-info">
          Accidents happen, but with Rocky, you’re prepared. 
          </p>
        </div>
      </Container>
    </section>
  );
}
